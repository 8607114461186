import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { HeatmapFakeData } from '~/packages/attrition/fake-data/heatmap.fake-data';
import { HeatmapItemType } from '~/shared/ui/chart/heatmap/types/heatmap-item.type';
import { HeatmapTrendType } from '~/shared/ui/chart/heatmap/types/heatmap-trend.type';
import { HeatmapColumnsEnum } from '@/packages/attrition/enums/heatmap-columns.enum';
import { DatePickerPeriodEnum } from '~/shared/ui/form/date-picker/enums/date-picker-period.enum';
import { IHeatmapDto } from '~/shared/ui/chart/heatmap/types/i-heatmap-dto';
import { FilterKeyEnum } from '../../filter/enums/filter-key.enum';

export class HeatmapService extends AppService {
  public fetchHeatmapData(options: IHeatmapDto, isDemo = false): Promise<HeatmapItemType[]> {
    if (Faker.isEnabled() || isDemo) {
      return HeatmapFakeData.getHeatmapData(options);
    }

    const {
      starts,
      ends,
      groupingId,
      targeting = {},
      grouping = FilterKeyEnum.TEAM_MANAGER,
      period = DatePickerPeriodEnum.MONTH,
    } = options;

    const dto: IHeatmapDto = {
      grouping,
      targeting,
      period,
      starts,
      ends,
    };

    if (groupingId !== undefined) dto.groupingId = groupingId;

    return this.api.core.post(`/attrition/heatmap/heatmap`, dto).then((response: any) => response.data.payload.data);
  }

  public fetchHeatmapCellTrendData(
    options: {
      starts: string;
      ends: string;
      targeting: FilterCriteria;
      grouping?: FilterKeyEnum | string;
      period?: DatePickerPeriodEnum;
      metric: string;
      id: string | number;
      isUser?: boolean;
    },
    isDemo = false,
    requestOptions = {}
  ): Promise<HeatmapTrendType> {
    if (Faker.isEnabled() || isDemo) {
      return HeatmapFakeData.getHeatmapCellTrendData();
    }

    const {
      starts,
      ends,
      metric,
      id,
      targeting,
      isUser = false,
      grouping = FilterKeyEnum.TEAM_MANAGER,
      period = DatePickerPeriodEnum.MONTH,
    } = options;

    return this.api.core
      .post(
        `/attrition/heatmap/trend`,
        {
          grouping: grouping.toUpperCase(),
          targeting,
          period,
          starts,
          ends,
          metric,
          id,
          isUser,
        },
        requestOptions
      )
      .then((response: any) =>
        response.data.payload.data.reduce((acc: HeatmapTrendType, item: Record<string, any>) => {
          if (!acc[HeatmapColumnsEnum.ENGAGEMENT_DIFFERENCE]) {
            acc[HeatmapColumnsEnum.ENGAGEMENT_DIFFERENCE] = [];
          }

          acc[HeatmapColumnsEnum.ENGAGEMENT_DIFFERENCE]?.push({
            date: item.date,
            value: item[HeatmapColumnsEnum.ENGAGEMENT_DIFFERENCE],
          });

          if (!acc[HeatmapColumnsEnum.PRODUCTIVITY_DIFFERENCE]) {
            acc[HeatmapColumnsEnum.PRODUCTIVITY_DIFFERENCE] = [];
          }

          acc[HeatmapColumnsEnum.PRODUCTIVITY_DIFFERENCE]?.push({
            date: item.date,
            value: item[HeatmapColumnsEnum.PRODUCTIVITY_DIFFERENCE],
          });

          if (!acc[HeatmapColumnsEnum.BURNOUT_DIFFERENCE]) {
            acc[HeatmapColumnsEnum.BURNOUT_DIFFERENCE] = [];
          }

          acc[HeatmapColumnsEnum.BURNOUT_DIFFERENCE]?.push({
            date: item.date,
            value: item[HeatmapColumnsEnum.BURNOUT_DIFFERENCE],
          });

          return acc;
        }, {})
      );
  }
}
