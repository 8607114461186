export default function declOfNum(count, text) {
  if (typeof text === 'string') {
    return `${text}${Math.abs(count) === 1 ? '' : 's'}`;
  }

  if (Array.isArray(text)) {
    return Math.abs(count) > 1 ? text[1] : text[0];
  }

  console.error('wrong arguments in declOfNum', { count, text });
  return '';
}
