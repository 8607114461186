import { AxiosResponse } from 'axios';
import { AppService } from '~/app/app.service';
import { ResponsePayloadType } from '~/shared/api/types/dto/response-payload.type';
import { ConfigType } from '@/packages/integration/mattermost/types/config.type';
import { UserInfoType } from '@/packages/integration/mattermost/types/user-info.type';

export class MattermostService extends AppService {
  public fetchConfig(): Promise<ConfigType> {
    return this.api.core
      .get('/integrations/mattermost/config')
      .then((response: AxiosResponse<ResponsePayloadType<ConfigType>>) => response.data.payload);
  }

  public saveConfig(config: ConfigType): Promise<ConfigType> {
    return this.api.core
      .post('/integrations/mattermost/config', config)
      .then((response: AxiosResponse<ResponsePayloadType<ConfigType>>) => response.data.payload);
  }

  public fetchUserInfo(): Promise<UserInfoType> {
    return this.api.core
      .get('/integrations/mattermost')
      .then((response: AxiosResponse<ResponsePayloadType<UserInfoType>>) => response.data.payload);
  }

  public auth(code: string, redirectUri: string) {
    return this.api.core
      .post('/integrations/mattermost/auth', {
        code,
        redirectUri,
      })
      .then((response: AxiosResponse<ResponsePayloadType<UserInfoType>>) => response.data.payload);
  }

  public fetchBotId(apiUrl: string, accessToken: string): Promise<string> {
    return this.api.core
      .post('/integrations/mattermost/config/bot-id', {
        apiUrl,
        accessToken,
      })
      .then(
        (response: AxiosResponse<ResponsePayloadType<{ id: string }>>) => response.data.payload.id
      );
  }
}
