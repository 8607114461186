import { ResponsePayloadType } from '~/shared/api/types/dto/response-payload.type';
import { Faker } from '@/packages/fake-data/faker';
import { AppService } from '~/app/app.service';
import {
  IntegrationsStatusType,
  IntegrationsAvailableDatesType,
} from '@/packages/integration/types/integration.types';
import { IntegrationFakeService } from '@/packages/fake-data/integration/integration.fake-service';
import { AxiosResponse } from 'axios';
import enableAllIntegrations from '@/packages/integration/helpers/enable-all-integrations';
import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import setMinAvailableDates from './helpers/set-min-available-dates';

type ContactSalesFormType = {
  subject: string;
  integration?: string;
  message: string;
};

export class IntegrationService extends AppService {
  public fetchStatus(): Promise<IntegrationsStatusType> {
    if (Faker.isEnabled()) {
      return IntegrationFakeService.getStatus();
    }

    return this.api.core
      .get('/integrations')
      .then((response: AxiosResponse<ResponsePayloadType<IntegrationsStatusType>>) => {
        const config = response.data.payload;

        if (Faker.isEnabled()) {
          enableAllIntegrations(config);

          if (config.BAMBOOHR) {
            config[IntegrationEnum.WORKDAY] = config.BAMBOOHR;
            config[IntegrationEnum.HIBOB] = config.BAMBOOHR;
          }
        }

        return config;
      });
  }

  public fetchAvailableDates(): Promise<IntegrationsAvailableDatesType> {
    if (Faker.isEnabled()) {
      return IntegrationFakeService.getAvailableDates();
    }

    return this.api.core.get('/integrations/analytics/status/dates').then(response => {
      const dates = response.data.payload.dates;

      if (Faker.isEnabled()) {
        const min = new Date(2020, 0, 1);
        setMinAvailableDates(dates, min);
      }

      return dates;
    });
  }

  sendContactSalesRequest(form: ContactSalesFormType) {
    return this.api.core.post('/integrations/request', form);
  }
}
