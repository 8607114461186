import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { SurveyTaskType } from '@/packages/attrition/types/survey-task.type';
import { SurveyStatusEnum } from '@/packages/attrition/enums/survey-status.enum';

dayjs.extend(utcPlugin);

function isTaskDismissedByEmployees(task: SurveyTaskType) {
  const now = dayjs.utc();
  const isSent = task?.status === SurveyStatusEnum.SENT;

  return isSent && now.isAfter(dayjs.utc(task?.answerDeadline)) && task.completed === 0;
}

export default isTaskDismissedByEmployees;
