import { default as driverspg4W5hiJFBMeta } from "/app/src/pages/attrition/drivers.vue?macro=true";
import { default as heatmappX8HGQ7K5UMeta } from "/app/src/pages/attrition/heatmap.vue?macro=true";
import { default as indexLJAyJClfuaMeta } from "/app/src/pages/attrition/index.vue?macro=true";
import { default as resultsjojicYIfK5Meta } from "/app/src/pages/attrition/results.vue?macro=true";
import { default as cost_45driversynpBIGbR0yMeta } from "/app/src/pages/cost-drivers.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as integrationsdkCh6dm78uMeta } from "/app/src/pages/integrations.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as logoutSGKlAvs2ilMeta } from "/app/src/pages/logout.vue?macro=true";
import { default as alert_45result8nyuTe3WnYMeta } from "/app/src/pages/pdf/alert-result.vue?macro=true";
import { default as driversdzY6sHrnEnMeta } from "/app/src/pages/pdf/drivers.vue?macro=true";
import { default as heatmaphOsBCBosL2Meta } from "/app/src/pages/pdf/heatmap.vue?macro=true";
import { default as resultsM66OLH6Bs7Meta } from "/app/src/pages/pdf/results.vue?macro=true";
import { default as team_45resulttlVY4e9twBMeta } from "/app/src/pages/pdf/team-result.vue?macro=true";
import { default as peopleAgnXIOzRj7Meta } from "/app/src/pages/people.vue?macro=true";
import { default as heatmapAlpvMyfBahMeta } from "/app/src/pages/productivity/heatmap.vue?macro=true";
import { default as trendscgSgBqgomDMeta } from "/app/src/pages/productivity/trends.vue?macro=true";
import { default as employeezdDnLhGXOJMeta } from "/app/src/pages/profile/employee.vue?macro=true";
import { default as revenue_45driverslwRNT01bXxMeta } from "/app/src/pages/revenue-drivers.vue?macro=true";
import { default as revenue_45risksbyvcLkhrrsMeta } from "/app/src/pages/revenue-risks.vue?macro=true";
import { default as segmentsXFLSX6rL5aMeta } from "/app/src/pages/segments.vue?macro=true";
import { default as indexu0UTCGKQMpMeta } from "/app/src/pages/survey/a/[uuid]/index.vue?macro=true";
import { default as startPlirGA2mPNMeta } from "/app/src/pages/survey/a/[uuid]/start.vue?macro=true";
export default [
  {
    name: driverspg4W5hiJFBMeta?.name ?? "attrition-drivers",
    path: driverspg4W5hiJFBMeta?.path ?? "/attrition/drivers",
    meta: driverspg4W5hiJFBMeta || {},
    alias: driverspg4W5hiJFBMeta?.alias || [],
    redirect: driverspg4W5hiJFBMeta?.redirect,
    component: () => import("/app/src/pages/attrition/drivers.vue").then(m => m.default || m)
  },
  {
    name: heatmappX8HGQ7K5UMeta?.name ?? "attrition-heatmap",
    path: heatmappX8HGQ7K5UMeta?.path ?? "/attrition/heatmap",
    meta: heatmappX8HGQ7K5UMeta || {},
    alias: heatmappX8HGQ7K5UMeta?.alias || [],
    redirect: heatmappX8HGQ7K5UMeta?.redirect,
    component: () => import("/app/src/pages/attrition/heatmap.vue").then(m => m.default || m)
  },
  {
    name: indexLJAyJClfuaMeta?.name ?? "attrition",
    path: indexLJAyJClfuaMeta?.path ?? "/attrition",
    meta: indexLJAyJClfuaMeta || {},
    alias: indexLJAyJClfuaMeta?.alias || [],
    redirect: indexLJAyJClfuaMeta?.redirect,
    component: () => import("/app/src/pages/attrition/index.vue").then(m => m.default || m)
  },
  {
    name: resultsjojicYIfK5Meta?.name ?? "attrition-results",
    path: resultsjojicYIfK5Meta?.path ?? "/attrition/results",
    meta: resultsjojicYIfK5Meta || {},
    alias: resultsjojicYIfK5Meta?.alias || [],
    redirect: resultsjojicYIfK5Meta?.redirect,
    component: () => import("/app/src/pages/attrition/results.vue").then(m => m.default || m)
  },
  {
    name: cost_45driversynpBIGbR0yMeta?.name ?? "cost-drivers",
    path: cost_45driversynpBIGbR0yMeta?.path ?? "/cost-drivers",
    meta: cost_45driversynpBIGbR0yMeta || {},
    alias: cost_45driversynpBIGbR0yMeta?.alias || [],
    redirect: cost_45driversynpBIGbR0yMeta?.redirect,
    component: () => import("/app/src/pages/cost-drivers.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsdkCh6dm78uMeta?.name ?? "integrations",
    path: integrationsdkCh6dm78uMeta?.path ?? "/integrations",
    meta: integrationsdkCh6dm78uMeta || {},
    alias: integrationsdkCh6dm78uMeta?.alias || [],
    redirect: integrationsdkCh6dm78uMeta?.redirect,
    component: () => import("/app/src/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: login3hUYZUGxmUMeta?.name ?? "login",
    path: login3hUYZUGxmUMeta?.path ?? "/login",
    meta: login3hUYZUGxmUMeta || {},
    alias: login3hUYZUGxmUMeta?.alias || [],
    redirect: login3hUYZUGxmUMeta?.redirect,
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutSGKlAvs2ilMeta?.name ?? "logout",
    path: logoutSGKlAvs2ilMeta?.path ?? "/logout",
    meta: logoutSGKlAvs2ilMeta || {},
    alias: logoutSGKlAvs2ilMeta?.alias || [],
    redirect: logoutSGKlAvs2ilMeta?.redirect,
    component: () => import("/app/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: alert_45result8nyuTe3WnYMeta?.name ?? "pdf-alert-result",
    path: alert_45result8nyuTe3WnYMeta?.path ?? "/pdf/alert-result",
    meta: alert_45result8nyuTe3WnYMeta || {},
    alias: alert_45result8nyuTe3WnYMeta?.alias || [],
    redirect: alert_45result8nyuTe3WnYMeta?.redirect,
    component: () => import("/app/src/pages/pdf/alert-result.vue").then(m => m.default || m)
  },
  {
    name: driversdzY6sHrnEnMeta?.name ?? "pdf-drivers",
    path: driversdzY6sHrnEnMeta?.path ?? "/pdf/drivers",
    meta: driversdzY6sHrnEnMeta || {},
    alias: driversdzY6sHrnEnMeta?.alias || [],
    redirect: driversdzY6sHrnEnMeta?.redirect,
    component: () => import("/app/src/pages/pdf/drivers.vue").then(m => m.default || m)
  },
  {
    name: heatmaphOsBCBosL2Meta?.name ?? "pdf-heatmap",
    path: heatmaphOsBCBosL2Meta?.path ?? "/pdf/heatmap",
    meta: heatmaphOsBCBosL2Meta || {},
    alias: heatmaphOsBCBosL2Meta?.alias || [],
    redirect: heatmaphOsBCBosL2Meta?.redirect,
    component: () => import("/app/src/pages/pdf/heatmap.vue").then(m => m.default || m)
  },
  {
    name: resultsM66OLH6Bs7Meta?.name ?? "pdf-results",
    path: resultsM66OLH6Bs7Meta?.path ?? "/pdf/results",
    meta: resultsM66OLH6Bs7Meta || {},
    alias: resultsM66OLH6Bs7Meta?.alias || [],
    redirect: resultsM66OLH6Bs7Meta?.redirect,
    component: () => import("/app/src/pages/pdf/results.vue").then(m => m.default || m)
  },
  {
    name: team_45resulttlVY4e9twBMeta?.name ?? "pdf-team-result",
    path: team_45resulttlVY4e9twBMeta?.path ?? "/pdf/team-result",
    meta: team_45resulttlVY4e9twBMeta || {},
    alias: team_45resulttlVY4e9twBMeta?.alias || [],
    redirect: team_45resulttlVY4e9twBMeta?.redirect,
    component: () => import("/app/src/pages/pdf/team-result.vue").then(m => m.default || m)
  },
  {
    name: peopleAgnXIOzRj7Meta?.name ?? "people",
    path: peopleAgnXIOzRj7Meta?.path ?? "/people",
    meta: peopleAgnXIOzRj7Meta || {},
    alias: peopleAgnXIOzRj7Meta?.alias || [],
    redirect: peopleAgnXIOzRj7Meta?.redirect,
    component: () => import("/app/src/pages/people.vue").then(m => m.default || m)
  },
  {
    name: heatmapAlpvMyfBahMeta?.name ?? "productivity-heatmap",
    path: heatmapAlpvMyfBahMeta?.path ?? "/productivity/heatmap",
    meta: heatmapAlpvMyfBahMeta || {},
    alias: heatmapAlpvMyfBahMeta?.alias || [],
    redirect: heatmapAlpvMyfBahMeta?.redirect,
    component: () => import("/app/src/pages/productivity/heatmap.vue").then(m => m.default || m)
  },
  {
    name: trendscgSgBqgomDMeta?.name ?? "productivity-trends",
    path: trendscgSgBqgomDMeta?.path ?? "/productivity/trends",
    meta: trendscgSgBqgomDMeta || {},
    alias: trendscgSgBqgomDMeta?.alias || [],
    redirect: trendscgSgBqgomDMeta?.redirect,
    component: () => import("/app/src/pages/productivity/trends.vue").then(m => m.default || m)
  },
  {
    name: employeezdDnLhGXOJMeta?.name ?? "profile-employee",
    path: employeezdDnLhGXOJMeta?.path ?? "/profile/employee",
    meta: employeezdDnLhGXOJMeta || {},
    alias: employeezdDnLhGXOJMeta?.alias || [],
    redirect: employeezdDnLhGXOJMeta?.redirect,
    component: () => import("/app/src/pages/profile/employee.vue").then(m => m.default || m)
  },
  {
    name: revenue_45driverslwRNT01bXxMeta?.name ?? "revenue-drivers",
    path: revenue_45driverslwRNT01bXxMeta?.path ?? "/revenue-drivers",
    meta: revenue_45driverslwRNT01bXxMeta || {},
    alias: revenue_45driverslwRNT01bXxMeta?.alias || [],
    redirect: revenue_45driverslwRNT01bXxMeta?.redirect,
    component: () => import("/app/src/pages/revenue-drivers.vue").then(m => m.default || m)
  },
  {
    name: revenue_45risksbyvcLkhrrsMeta?.name ?? "revenue-risks",
    path: revenue_45risksbyvcLkhrrsMeta?.path ?? "/revenue-risks",
    meta: revenue_45risksbyvcLkhrrsMeta || {},
    alias: revenue_45risksbyvcLkhrrsMeta?.alias || [],
    redirect: revenue_45risksbyvcLkhrrsMeta?.redirect,
    component: () => import("/app/src/pages/revenue-risks.vue").then(m => m.default || m)
  },
  {
    name: segmentsXFLSX6rL5aMeta?.name ?? "segments",
    path: segmentsXFLSX6rL5aMeta?.path ?? "/segments",
    meta: segmentsXFLSX6rL5aMeta || {},
    alias: segmentsXFLSX6rL5aMeta?.alias || [],
    redirect: segmentsXFLSX6rL5aMeta?.redirect,
    component: () => import("/app/src/pages/segments.vue").then(m => m.default || m)
  },
  {
    name: indexu0UTCGKQMpMeta?.name ?? "survey-a-uuid",
    path: indexu0UTCGKQMpMeta?.path ?? "/survey/a/:uuid()",
    meta: indexu0UTCGKQMpMeta || {},
    alias: indexu0UTCGKQMpMeta?.alias || [],
    redirect: indexu0UTCGKQMpMeta?.redirect,
    component: () => import("/app/src/pages/survey/a/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: startPlirGA2mPNMeta?.name ?? "survey-a-uuid-start",
    path: startPlirGA2mPNMeta?.path ?? "/survey/a/:uuid()/start",
    meta: startPlirGA2mPNMeta || {},
    alias: startPlirGA2mPNMeta?.alias || [],
    redirect: startPlirGA2mPNMeta?.redirect,
    component: () => import("/app/src/pages/survey/a/[uuid]/start.vue").then(m => m.default || m)
  }
]