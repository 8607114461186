import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

export default function getShortString(date, { formatToUTC } = { formatToUTC: false }) {
  if (!date) {
    return '–';
  }

  const now = dayjs();
  const dateObject = dayjs(date);

  if (dateObject.isToday()) {
    const difference = {
      inHours: now.diff(date, 'hour'),
      inMinutes: now.diff(date, 'minute'),
    };

    if (difference.inHours > 0) {
      return `${difference.inHours}h`;
    }

    if (difference.inMinutes > 0) {
      return `${difference.inMinutes}m`;
    }

    return 'Just now';
  }

  if (dateObject.isYesterday()) {
    return 'Yesterday';
  }

  if (dateObject.format('YYYY') !== now.format('YYYY')) {
    if (formatToUTC) {
      return dayjs.utc(date).format('d MMM yyyy');
    }

    return dateObject.format('D MMM YYYY');
  }

  if (formatToUTC) {
    return dayjs.utc(date).format('d MMM');
  }

  return dateObject.format('D MMM');
}
