import dayjs from 'dayjs';

import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export default date => {
  if (!date) {
    return 'Not a date';
  }

  const dateObject = dayjs(date);

  if (dateObject.isYesterday()) {
    return `Yesterday, ${dateObject.format('D MMM, ddd')}`;
  }

  if (dateObject.isToday()) {
    return `Today, ${dateObject.format('D MMM, ddd')}`;
  }

  if (dateObject.isTomorrow()) {
    return `Tomorrow, ${dateObject.format('D MMM, ddd')}`;
  }

  const now = dayjs();

  if (dateObject.format('YYYY') !== now.format('YYYY')) {
    return `${dateObject.format('D MMM YYYY, ddd')}`;
  }

  return `${dateObject.format('D MMM, ddd')}`;
};
