import deepMerge from 'deepmerge';
import { cloneDeep } from 'lodash-es';
import { AlertType } from '~/packages/attrition/types/alert.type';
import { SurveyStatusEnum } from '~/packages/attrition/enums/survey-status.enum';
import { SuggestionStatusEnum } from '~/packages/attrition/enums/suggestion-status.enum';
import { AlertTeamType } from '~/packages/attrition/types/alert-team.type';
import { SignalCategoryEnum } from '~/packages/attrition/enums/signal-category.enum';
import { LikelyToStayEnum } from '~/packages/attrition/enums/likely-to-stay.enum';
import { FeelValuedEnum } from '~/packages/attrition/enums/feel-valued.enum';
import { SurveysResultType } from '~/packages/attrition/types/survey-result.type';
import { ReasonsKeyEnum } from '~/packages/attrition/enums/reasons-key.enum';

const baseAlert = {
  title:
    'High chance of {leavingEmployee} people in {userGenerationName} in {departmentName} leaving next month',
  createdAt: '2022-03-15T10:51:40.850Z',
  description:
    "{riskUsers} out of {activeUsers} employees are beyond {meanAttritionTenure} {meanAttritionTenureLabel} tenure at the company which is the mean attrition tenure for this age group.\n{usersWillLeave} employees are in the risk group based on your company's historic data.\nThere is a high chance ({confidence}% confidence) of {leavingEmployee} employees leaving next month.",
  replacements: {
    userGenerationName: 'Generation Z',
    departmentName: 'Engineering',
    riskUsers: 242,
    activeUsers: 536,
    meanAttritionTenure: 1.6,
    meanAttritionTenureLabel: 'years',
    usersWillLeave: 87,
    leavingEmployee: 5,
    confidence: 79.6,
  },

  signals: [
    {
      text: 'On average, the last promotion for these {employees} {employeesLabel} was {tenure} {tenureLabel} ago',
      replacements: {
        employees: 5,
        employeesLabel: 'employees',
        tenure: 2.6,
        tenureLabel: 'years',
      },
      categoryUid: SignalCategoryEnum.PEOPLE,
    },
    {
      text: '91% decrease in total meetings in Zoom MoM for these 5 people',
      replacements: {},
      categoryUid: SignalCategoryEnum.CALLS,
    },
    {
      text: '55% decrease in sent emails in Outlook MoM for these 5 people',
      replacements: {},
      categoryUid: SignalCategoryEnum.EMAILS,
    },
    {
      text: '25% increase in private channel messages in Slack MoM for these 5 people\n',
      replacements: {},
      categoryUid: SignalCategoryEnum.MESSAGES,
    },
  ],
  initialSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    availableAt: '2022-03-01T10:51:40.850Z',
    answerDeadline: '2022-07-01T10:51:40.850Z',
    createDeadline: '2023-07-01T10:51:40.850Z',
  },
  finalSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    availableAt: '2022-04-10T10:51:40.850Z',
    answerDeadline: '2023-07-01T10:51:40.850Z',
  },
  performanceSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    availableAt: '2022-02-01T10:51:40.850Z',
    answerDeadline: '2023-09-01T10:51:40.850Z',
  },
  suggestionsForManagers: {
    status: SuggestionStatusEnum.SENT,
    availableAt: '2022-02-15T10:51:40.850Z',
    suggestions: [
      {
        id: 1,
        title: 'Ask managers to conduct face-to-face in-depth engagement interviews',
        managers: ['21', '10'],
      },
    ],
  },
};

const alert1: AlertType = deepMerge(baseAlert, {
  id: 'demo-1',
  performanceSurvey: { status: SurveyStatusEnum.NOT_SENT },
  initialSurvey: { status: SurveyStatusEnum.NOT_SENT },
  suggestionsForManagers: { status: SuggestionStatusEnum.NOT_SENT },
  finalSurvey: { status: SurveyStatusEnum.NOT_SENT },
} as Partial<AlertType>);

const alert2: AlertType = deepMerge(baseAlert, {
  id: 'demo-2',
  performanceSurvey: { status: SurveyStatusEnum.SENT },
  initialSurvey: { status: SurveyStatusEnum.NOT_SENT },
  suggestionsForManagers: { status: SuggestionStatusEnum.NOT_SENT },
  finalSurvey: { status: SurveyStatusEnum.NOT_SENT },
} as Partial<AlertType>);

const alert3: AlertType = deepMerge(baseAlert, {
  id: 'demo-3',
  performanceSurvey: { status: SurveyStatusEnum.SENT },
  initialSurvey: { status: SurveyStatusEnum.SENT },
  suggestionsForManagers: { status: SuggestionStatusEnum.NOT_SENT },
  finalSurvey: { status: SurveyStatusEnum.NOT_SENT },
} as Partial<AlertType>);

const alert4: AlertType = deepMerge(baseAlert, {
  id: 'demo-4',
  performanceSurvey: { status: SurveyStatusEnum.COMPLETED },
  initialSurvey: { status: SurveyStatusEnum.COMPLETED },
  suggestionsForManagers: { status: SuggestionStatusEnum.NOT_SENT },
  finalSurvey: { status: SurveyStatusEnum.NOT_SENT },
} as Partial<AlertType>);

const alert5: AlertType = deepMerge(baseAlert, {
  id: 'demo-5',
  performanceSurvey: { status: SurveyStatusEnum.COMPLETED },
  initialSurvey: { status: SurveyStatusEnum.COMPLETED },
  suggestionsForManagers: { status: SuggestionStatusEnum.SENT },
  finalSurvey: { status: SurveyStatusEnum.NOT_SENT },
} as Partial<AlertType>);

const alert6: AlertType = deepMerge(baseAlert, {
  id: 'demo-6',
  performanceSurvey: { status: SurveyStatusEnum.COMPLETED },
  initialSurvey: { status: SurveyStatusEnum.COMPLETED },
  suggestionsForManagers: {
    status: SuggestionStatusEnum.SENT,
  },
  finalSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    availableAt: new Date().toISOString(),
  },
} as Partial<AlertType>);

const alert7: AlertType = deepMerge(baseAlert, {
  id: 'demo-7',
  performanceSurvey: {
    status: SurveyStatusEnum.COMPLETED,
    answerDeadline: new Date().toISOString(),
  },
  initialSurvey: {
    status: SurveyStatusEnum.COMPLETED,
    answerDeadline: new Date().toISOString(),
  },
  suggestionsForManagers: { status: SuggestionStatusEnum.SENT },
  finalSurvey: {
    status: SurveyStatusEnum.SENT,
    availableAt: new Date().toISOString(),
  },
} as Partial<AlertType>);

const alert8: AlertType = deepMerge(baseAlert, {
  id: 'demo',
  performanceSurvey: {
    status: SurveyStatusEnum.COMPLETED,
    totalAvailable: 10,
    completed: 10,
    sent: 10,
  },
  initialSurvey: {
    status: SurveyStatusEnum.COMPLETED,
    totalAvailable: 10,
    completed: 10,
    sent: 10,
  },
  suggestionsForManagers: { status: SuggestionStatusEnum.SENT },
  finalSurvey: {
    status: SurveyStatusEnum.COMPLETED,
    totalAvailable: 10,
    completed: 10,
    sent: 10,
    createDeadline: '2022-09-15T10:51:40.850Z',
    answerDeadline: '2022-09-15T10:51:40.850Z',
  },
} as Partial<AlertType>);

const alert9: AlertType = deepMerge(baseAlert, {
  id: 'demo-9',
  createdAt: '2022-03-15T10:51:40.850Z',
  performanceSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    totalAvailable: 9,
    completed: 0,
    sent: 0,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
  },
  initialSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    totalAvailable: 9,
    completed: 0,
    sent: 0,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
  },
  suggestionsForManagers: {
    status: SuggestionStatusEnum.NOT_SENT,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
    suggestions: [],
  },
  finalSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    completed: 0,
    sent: 0,
    totalAvailable: 9,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
  },
} as Partial<AlertType>);

const alert10: AlertType = deepMerge(baseAlert, {
  id: 'demo-10',
  performanceSurvey: {
    status: SurveyStatusEnum.SENT,
    totalAvailable: 9,
    completed: 0,
    sent: 1,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
  },
  initialSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    totalAvailable: 9,
    completed: 0,
    sent: 0,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
  },
  suggestionsForManagers: {
    status: SuggestionStatusEnum.NOT_SENT,
    completed: 0,
    sent: 0,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
    suggestions: [],
  },
  finalSurvey: {
    status: SurveyStatusEnum.NOT_SENT,
    totalAvailable: 9,
    completed: 0,
    sent: 0,
    answerDeadline: new Date().toISOString(),
    createDeadline: new Date().toISOString(),
    availableAt: new Date().toISOString(),
  },
} as Partial<AlertType>);

const alerts = [alert1, alert2, alert3, alert4, alert5, alert6, alert7, alert8, alert9, alert10];

const teamResults: SurveysResultType = {
  employees: [
    {
      id: '10',
      name: 'Erlich Bachman',
      email: '',
      rating: 1,
      initialSurvey: {
        id: '101',
        status: SurveyStatusEnum.COMPLETED,
        availableAt: '2022-04-015T10:51:40.850Z',
        initiatedAt: '2022-02-03T12:51:40.850Z',
        answerDeadline: '2022-04-26T10:51:40.850Z',
        createDeadline: '2022-04-26T10:51:40.850Z',
        sentLastReminderAt: null,
      },
      finalSurvey: {
        id: '102',
        status: SurveyStatusEnum.COMPLETED,
        availableAt: '2022-05-02T06:27:24.366Z',
        initiatedAt: '2022-02-03T12:51:40.850Z',
        answerDeadline: '2022-04-26T10:51:40.850Z',
        createDeadline: '2022-04-26T10:51:40.850Z',
        sentLastReminderAt: null,
      },
    },
    {
      id: '11',
      name: 'Dinesh Chugtai',
      email: '',
      rating: 3,
      initialSurvey: {
        id: '111',
        status: SurveyStatusEnum.COMPLETED,
        availableAt: '2022-02-01T10:51:40.850Z',
        initiatedAt: '2022-02-03T12:51:40.850Z',
        answerDeadline: '2022-04-26T10:51:40.850Z',
        createDeadline: '2022-04-26T10:51:40.850Z',
        sentLastReminderAt: null,
      },
      finalSurvey: {
        id: '112',
        status: SurveyStatusEnum.COMPLETED,
        availableAt: '2022-05-02T06:27:24.366Z',
        initiatedAt: '2022-02-03T12:51:40.850Z',
        answerDeadline: '2022-04-26T10:51:40.850Z',
        createDeadline: '2022-04-26T10:51:40.850Z',
        sentLastReminderAt: null,
      },
    },
    {
      id: '12',
      name: 'Russ Hanneman',
      email: '',
      rating: 4,
      initialSurvey: {
        id: '121',
        status: SurveyStatusEnum.COMPLETED,
        availableAt: '2022-02-01T10:51:40.850Z',
        initiatedAt: '2022-02-03T12:51:40.850Z',
        answerDeadline: '2022-04-26T10:51:40.850Z',
        createDeadline: '2022-04-26T10:51:40.850Z',
        sentLastReminderAt: null,
      },
      finalSurvey: {
        id: '122',
        status: SurveyStatusEnum.COMPLETED,
        availableAt: '2022-02-01T10:51:40.850Z',
        initiatedAt: '2022-05-02T06:27:24.366Z',
        answerDeadline: '2022-04-26T10:51:40.850Z',
        createDeadline: '2022-04-26T10:51:40.850Z',
        sentLastReminderAt: null,
      },
    },
  ],
  likelyToStay: {
    '2022-04-15': {
      [LikelyToStayEnum.I_DONT_KNOW]: 1,
      [LikelyToStayEnum.NOT_SELECTED]: 1,
      [LikelyToStayEnum.LESS_THAN_3_MONTHS]: 2,
      [LikelyToStayEnum.LESS_THAN_6_MONTHS]: 1,
      [LikelyToStayEnum.LESS_THAN_12_MONTHS]: 1,
      [LikelyToStayEnum.LESS_THAN_24_MONTHS]: 2,
    },
    '2022-05-15': {
      [LikelyToStayEnum.LESS_THAN_6_MONTHS]: 2,
      [LikelyToStayEnum.LESS_THAN_12_MONTHS]: 1,
      [LikelyToStayEnum.LESS_THAN_24_MONTHS]: 3,
      [LikelyToStayEnum.I_DONT_KNOW]: 2,
    },
  },
  explain: {
    '2022-04-15': [
      'I don’t like my manager',
      'I don’t feel comfortable expressing my opinions in meetings',
    ],
    '2022-05-15': ['I am afraid to speak up', 'I feel undervalued'],
  },
  reasons: {
    [ReasonsKeyEnum.MANAGER]: 4,
    [ReasonsKeyEnum.ROLE]: 3,
    [ReasonsKeyEnum.HIGHER_PAY]: 2,
    [ReasonsKeyEnum.LACK_OF_OPPORTUNITIES]: 1,
    [ReasonsKeyEnum.COMPANY_VISION]: 1,
  },
  feelValue: {
    [FeelValuedEnum.AGREE]: 2,
    [FeelValuedEnum.STRONGLY_AGREE]: 1,
    [FeelValuedEnum.NOT_SELECTED]: 0,
    [FeelValuedEnum.STRONGLY_DISAGREE]: 1,
    [FeelValuedEnum.DISAGREE]: 1,
    [FeelValuedEnum.SLIGHTLY_DISAGREE]: 1,
    [FeelValuedEnum.NEUTRAL]: 2,
  },
};

export class AlertFakeData {
  public static getAlerts(): Promise<AlertType[]> {
    return Promise.resolve(cloneDeep(alerts));
  }

  public static getAlertTeams(): Record<string, AlertTeamType> {
    return {
      '1': {
        id: '1',
        teamId: 1,
        name: 'Gavin Belson’s team',
        affectedEmployees: 3,
        results: teamResults,
        members: {
          '10': {
            id: '10',
            teamId: '1',
            fullName: 'Gavin Belson',
            isManager: true,
            email: 'gavin@company.com',
            performanceSurvey: {
              id: '101',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-16T10:51:40.850Z',
            },
            suggestion: {
              id: '102',
              status: SuggestionStatusEnum.SENT,
              availableAt: '2022-04-15T10:51:40.850Z',
              createDeadline: '2022-04-15T10:51:40.850Z',
              sentAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '11': {
            id: '11',
            teamId: '1',
            fullName: 'Laurie Bream',
            isManager: false,
            email: 'laurie@company.com',
            initialSurvey: {
              id: '111',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '112',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '12': {
            id: '12',
            teamId: '1',
            fullName: 'Jian Yang',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '121',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '122',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-08-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '13': {
            id: '13',
            teamId: '1',
            fullName: 'Jian Yang',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '131',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '132',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '14': {
            id: '14',
            teamId: '1',
            fullName: 'Olaf Aries',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '131',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '132',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '15': {
            id: '15',
            teamId: '1',
            fullName: 'Michal Leighton',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '131',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '132',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '16': {
            id: '16',
            teamId: '1',
            fullName: 'Kallippos Sandile',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '131',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '132',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '17': {
            id: '17',
            teamId: '1',
            fullName: 'Meino Amit',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '131',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '132',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
        },
      },
      '2': {
        teamId: 2,
        id: '2',
        name: 'Jack Barker’s team',
        affectedEmployees: 2,
        results: teamResults,
        members: {
          '21': {
            id: '21',
            teamId: '2',
            fullName: 'Jack Barker',
            isManager: true,
            email: 'jack@company.com',
            performanceSurvey: {
              id: '211',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-08-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            suggestion: {
              id: '103',
              status: SuggestionStatusEnum.SENT,
              availableAt: '2022-04-15T10:51:40.850Z',
              createDeadline: '2022-04-15T10:51:40.850Z',
              sentAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '22': {
            id: '22',
            teamId: '2',
            fullName: 'Jared Dunn',
            isManager: false,
            email: 'jared@company.com',
            initialSurvey: {
              id: '221',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '222',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '23': {
            id: '23',
            teamId: '2',
            fullName: 'Big Head',
            isManager: false,
            email: 'big@company.com',
            initialSurvey: {
              id: '231',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '232',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '24': {
            id: '24',
            teamId: '2',
            fullName: 'Jian Yang',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '241',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '242',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '25': {
            id: '25',
            teamId: '2',
            fullName: 'Ike Mitchell',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '241',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '242',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '26': {
            id: '26',
            teamId: '2',
            fullName: 'Deforrest Glanville',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '241',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '242',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
          '27': {
            id: '27',
            teamId: '2',
            fullName: 'Cordell Dorian',
            isManager: false,
            email: 'jian@company.com',
            initialSurvey: {
              id: '241',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
            finalSurvey: {
              id: '242',
              status: SurveyStatusEnum.COMPLETED,
              availableAt: '2022-04-15T10:51:40.850Z',
              initiatedAt: '2022-04-15T10:51:40.850Z',
              answerDeadline: '2022-04-26T10:51:40.850Z',
              createDeadline: '2022-04-26T10:51:40.850Z',
              sentLastReminderAt: '2022-04-15T10:51:40.850Z',
            },
          },
        },
      },
    };
  }

  public static getAlert(alertId: string): Promise<AlertType> {
    return Promise.resolve({
      ...(alerts.find(alert => alert.id === alertId) as AlertType),
      teams: this.getAlertTeams(),
    });
  }

  // private getTeamMemberSurvey(status: "") {
  //   switch (status) {
  //     case SurveyStatusEnum.SENT:
  //   }
  // }
}
