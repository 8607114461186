import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/src/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "fetch-current-organisation": () => import("/app/src/app/middleware/fetch.current-organisation.ts"),
  "fetch-current-user": () => import("/app/src/app/middleware/fetch.current-user.ts"),
  "fetch-revenue-summary": () => import("/app/src/app/middleware/fetch.revenue-summary.ts"),
  "redirect-has-analytics-access-to-main": () => import("/app/src/app/middleware/redirect.has-analytics-access.to-main.ts"),
  "redirect-no-permission-to-main": () => import("/app/src/app/middleware/redirect.no-permission.to-main.ts"),
  "redirect-not-authenticated-to-login": () => import("/app/src/app/middleware/redirect.not-authenticated.to-login.ts"),
  "redirect-not-authenticated-to-survey-start": () => import("/app/src/app/middleware/redirect.not-authenticated.to-survey-start.ts")
}