import { AxiosResponse } from 'axios';
import { AppService } from '~/app/app.service';
import { ResponsePayloadType } from '~/shared/api/types/dto/response-payload.type';
import { ConfigType } from '@/packages/integration/slack/types/config.type';
import { UserType } from '@/packages/integration/slack/types/user.type';

export class SlackService extends AppService {
  public fetchConfig(): Promise<ConfigType> {
    return this.api.core
      .get('/integrations/slack/config')
      .then((response: AxiosResponse<ResponsePayloadType<ConfigType>>) => response.data.payload);
  }

  public saveConfig(config: ConfigType): Promise<ConfigType> {
    return this.api.core
      .post('/integrations/slack/config', config)
      .then((response: AxiosResponse<ResponsePayloadType<ConfigType>>) => response.data.payload);
  }

  public fetchOauthUri(redirectUri: string): Promise<string> {
    return this.api.core
      .post('/integrations/slack/oauth/get-uri', {
        redirectUri,
      })
      .then(
        (response: AxiosResponse<ResponsePayloadType<{ uri: string }>>) => response.data.payload.uri
      );
  }

  public fetchUserInfo(): Promise<UserType> {
    return this.api.core
      .get('/integrations/slack')
      .then(
        (response: AxiosResponse<ResponsePayloadType<{ userInfo: UserType }>>) =>
          response.data.payload.userInfo
      );
  }

  public connect(code: string, redirectUri: string): Promise<UserType> {
    return this.api.core
      .post('/integrations/slack/oauth/connect', {
        code,
        redirectUri,
      })
      .then(
        (response: AxiosResponse<ResponsePayloadType<{ userInfo: UserType }>>) =>
          response.data.payload.userInfo
      );
  }
}
