import declOfNum from './decl-of-num';

export default function countFormatter(count, text) {
  if (typeof text === 'string' || Array.isArray(text)) {
    return `${count} ${declOfNum(count, text)}`;
  }

  console.error('wrong arguments in countFormatter', { count, text });
  return count;
}
