<template>
  <Transition name="loader-modal">
    <div
      v-if="isLoading"
      class="bg-grey-4 fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center flex-col gap-6"
    >
      <LoaderLogo :size="48" />
    </div>
  </Transition>

  <NuxtLayout>
    <NuxtPage class="min-h-screen" />
    <Snackbar
      :visible="$ui.snackbar.visible"
      :message="$ui.snackbar?.text || ''"
      :type="$ui.snackbar?.type"
      @close="$ui.snackbar.hide()"
    />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import Snackbar from '~/shared/ui/snackbar/snackbar-message.vue';
import LoaderLogo from '~/shared/ui/loader/loader-logo.vue';
import { useWindowStore } from '~/shared/stores/window.store';

const app = useNuxtApp();
const isLoading = ref(true);

app.hook('page:finish', () => {
  isLoading.value = false;
});

const windowStore = useWindowStore();

function scrollHandler() {
  windowStore.$patch({
    scrollY: window.scrollY,
  });
}

function resizeHandler() {
  windowStore.$patch({
    height: window.innerHeight,
  });
}

onMounted(() => {
  window.addEventListener('scroll', scrollHandler, { passive: true });
  window.addEventListener('resize', resizeHandler, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener('scroll', scrollHandler);
  window.removeEventListener('resize', resizeHandler);
});
</script>

<style lang="scss" scoped>
.loader-modal {
  &-leave-active {
    transition: theme('transitionDuration.DEFAULT') theme('transitionTimingFunction.DEFAULT');
  }

  &-leave-to {
    opacity: 0;
    background-color: theme('colors.green.8');
  }
}
</style>
