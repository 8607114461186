import { AxiosResponse } from 'axios';
import { AppService } from '~/app/app.service';
import { ResponsePayloadType } from '~/shared/api/types/dto/response-payload.type';

export class PdfService extends AppService {
  generatePdf({ apiPath, payload }: generatePdfProps) {
    return this.api.pdf.post(apiPath, { data: payload }, { responseType: 'blob' });
  }

  async sendPdf({ data, filename }: pdfFile, { emails, message }: sendPdfProps): Promise<any> {
    const formData = new FormData();

    formData.append('emails', JSON.stringify(emails));
    formData.append('message', message);
    formData.append('report', new Blob([data]), filename);

    return this.api.core
      .post(`/integration-analytics/report/send`, formData)
      .then((response: AxiosResponse<ResponsePayloadType<any>>) => response.data.payload);
  }
}

export interface sendPdfProps {
  emails: string[];
  message: string;
}

export interface generatePdfProps {
  apiPath: string;
  payload: any;
}

export interface pdfFile {
  data: any;
  filename: string;
}
