import { SessionUserType } from '@/packages/user/types/auth.types';
import { PermissionEnum } from '@/packages/user/enums/permission.enums';
import {
  Permission,
  PermissionEvery,
  PermissionSome,
} from '@/packages/user/types/permission.types';
import { useSessionStore } from '@/packages/user/store/session.store';

export function userCan(
  permission: Permission | PermissionEvery | PermissionSome,
  user?: SessionUserType | undefined | null
): boolean {
  if (!user) {
    const session = useSessionStore();
    user = session.user;
  }

  if (!user) {
    return false;
  }

  if (typeof permission === 'string') {
    permission = [permission];
  }

  permission = permission as PermissionEvery | PermissionSome;

  if (!Array.isArray(permission[0])) {
    permission = [permission as PermissionEvery];
  }

  permission = permission as PermissionSome;

  const userPermissions = getUserPermissions(user);

  return permission.some(item => item.every(permission => userPermissions.includes(permission)));
}

export function getUserPermissions(user: SessionUserType) {
  return user.role.permissions;
}

export function getDashboardPagePermissions() {
  return [
    PermissionEnum.ATTRITION_ALERT_ALERT_VIEW,
    PermissionEnum.ATTRITION_DRIVER_VIEW,
    PermissionEnum.ATTRITION_HEATMAP_VIEW,
    PermissionEnum.ATTRITION_MONETARY_RESULT_VIEW,
    PermissionEnum.ATTRITION_COMPARISON_RESULT_VIEW,

    PermissionEnum.PRODUCTIVITY_HEATMAP_VIEW,
    PermissionEnum.PRODUCTIVITY_TREND_VIEW,

    PermissionEnum.INTEGRATION_VIEW,

    PermissionEnum.SEGMENT_VIEW,
  ];
}
